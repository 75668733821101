export default class BtnAddToCart
{
    constructor()
    {
        this.#init();
    }

    /**
     * Initialise les évenements
     */
    #init()
    {
        this.loadAll();
        this.#onUpdateCart();
        this.#onUpdateProductList();
    }

    /**
     * Action lorsque la liste des produits est modifiée
     */
    #onUpdateProductList()
    {
        prestashop.on('updateProductList', () =>{
            this.loadAll();
        });
    }

    /**
     * Initialise les évenements lorsque le panier est mis à jour
     */
    #onUpdateCart()
    {
        prestashop.on('updateCart', (event) =>{
            /**
             * @type {HTMLElement}
             */
            let btnList = document.getElementsByClassName('btn_add_to_cart');
    
            if (btnList.length > 0) {
                for (let i = 0; i < btnList.length; i++) {
                    /**
                     * @type {HTMLElement}
                     */
                    let btn = btnList[i];
    
                    /**
                     * @type {integrer}
                     */
                    let id_product = parseInt(btn.getAttribute('data-id_product'));
    
                    /**
                     * @type {integrer}
                     */
                    let id_product_attribute = parseInt(btn.getAttribute('data-id_product_attribute'));
    
                    /**
                     * @type {integrer}
                     */
                    let idProductResponse = 0;
    
                    /**
                     * @type {integrer}
                     */
                    let idProductAttributeResponse = 0;
    
                    if (event.resp.errors == "") {
    
                        if(id_product && event.reason.idProduct) {
                            /**
                             * @type {integrer}
                             */
                            idProductResponse = parseInt(event.reason.idProduct);
                        }
        
                        if(id_product_attribute && event.reason.idProductAttribute && event.reason.idProductAttribute!=0 && event.reason.idProductAttribute!=undefined) {
                            /**
                             * @type {integrer}
                             */
                            idProductAttributeResponse = parseInt(event.reason.idProductAttribute);
                        }
        
                        if (!(id_product == idProductResponse && id_product_attribute == idProductAttributeResponse)) {
                            continue;
                        }
        
                        btn.setAttribute('data-current_cart_product', event.resp.quantity);
        
                        /**
                         * @type {HTMLElement}
                         */
                        let fakeQuantityInput = document.getElementById(btn.getAttribute('data-fake_quantity_input'));
                
                        if (event.resp.quantity > 0) {
                            /**
                             * @type {HTMLElement}
                             */
                            let labelSpan = document.getElementById(btn.getAttribute('data-label'));
                
                            /**
                             * @type {String}
                             */
                            let labelUpdate = btn.getAttribute('data-lang_update');
                
                            labelSpan.innerHTML = labelUpdate;
                
                            btn.classList.add('in_cart');
                            
                            if (fakeQuantityInput) {
                                fakeQuantityInput.value = event.resp.quantity;
                            }
                        }
                    } else {
                        if(id_product && event.resp.errors && event.resp.errors.id_product) {
                            /**
                             * @type {integrer}
                             */
                            idProductResponse = parseInt(event.resp.errors.id_product);
                        }
        
                        if(id_product_attribute && event.resp.errors && event.resp.errors.id_product_attribute && event.resp.errors.id_product_attribute!=0 && event.resp.errors.id_product_attribute!=undefined) {
                            /**
                             * @type {integrer}
                             */
                            idProductAttributeResponse = parseInt(event.resp.errors.id_product_attribute);
                        }
        
                        if (!(id_product == idProductResponse && id_product_attribute == idProductAttributeResponse)) {
                            continue;
                        }
    
                        /**
                         * @type {HTMLElement}
                         */
                        let fake_quantity_input = document.getElementById(btn.getAttribute('data-fake_quantity_input'));
    
                        /**
                         * @type {integrer}
                         */
                        let currentCartQuantity = parseInt(btn.getAttribute('data-current_cart_product'));
    
                        if (fake_quantity_input) {
                            if (currentCartQuantity > 0) {
                                fake_quantity_input.value = currentCartQuantity;
                            } else {
                                fake_quantity_input.value = 1;
                                btn.disabled = false;
                            }
                        }
                    }
                }
            }
        });
    }

    /**
     * Requête pour récupérer les déclinaisons
     * 
     * @param {number} id_product 
     * @param {number} id_attribute 
     * 
     * @returns {Promise}
     */
    #getProductCombination(id_product) {
        /**
         * @var {HTMLElement}
         */
        let xyproductlist_token = document.getElementById('xyproductlist_token');

        /**
         * @type {Promise}
         */
        return new Promise((resolve, reject) => {
            $.ajax({
                url         : xyProductListDefaultUrl,
                type        : "POST",
                dataType    : 'json',
                data: {
                    myajax : 1,
                    action : 'getCombinationsList',
                    id_product : id_product,
                    token: xyproductlist_token.value
                },
                success: function(jsonResult) {
                    resolve(jsonResult);
                },
                error: function () {
                    reject('xyproductlist : getProductCombination grid/list ajax error');
                }
            });
        });
    }

    /**
     * Requête pour récupérer les images d'une déclinaison
     * 
     * @param {Integrer} id_product
     * @param {Integrer} id_attribute
     * 
     * @returns {Promise}
     */
    #updateXyProductListProductImages(id_product, id_attribute) {
        /**
         * @var {HTMLElement}
         */
        let xyproductlist_token = document.getElementById('xyproductlist_token');
    
        /**
         * @type {Promise}
         */
        return new Promise((resolve, reject) => {
            $.ajax({
                url         : xyProductListDefaultUrl,
                type        : "POST",
                dataType    : 'json',
                data: {
                    myajax : 1,
                    action : 'getImagesCombinations',
                    id_product : id_product,
                    id_attribute : id_attribute,
                    token: xyproductlist_token.value
                },
                success: function(jsonResult) {
                    resolve(jsonResult);
                },
                error: function () {
                    reject('xyproductlist : updateXyProductListProductImages grid/list ajax error');
                }
            });
        });
    }

    /**
     * Requête pour récupérer le bouton d'ajout d'une déclinaison
     * 
     * @param {Integrer} id_product
     * @param {Integrer} id_attribute
     * 
     * @returns {Promise}
     */
    #updateXyProductListProductAddToCart(id_product, id_attribute)
    {
        /**
         * @var {HTMLElement}
         */
        let xyproductlist_token = document.getElementById('xyproductlist_token');
    
        /**
         * @type {Promise}
         */
        return new Promise((resolve, reject) => {
            $.ajax({
                url         : xyProductListDefaultUrl,
                type        : "POST",
                dataType    : 'json',
                data: {
                    myajax : 1,
                    action : 'getAddToCart',
                    id_product : id_product,
                    id_attribute : id_attribute,
                    token: xyproductlist_token.value
                },
                success: function(jsonResult) {
                    resolve(jsonResult);
                },
                error: function () {
                    reject('xyproductlist : updateXyProductListProductAddToCart grid/list ajax error');
                }
            });
        });
    }

    /**
     * Lance tous les lecteurs
     */
    loadAll()
    {
        this.loadAddToCart();
        this.loadIncrement();
        this.loadTouchSpin();
        this.loadProductVariants();
        this.loadSelectCombination();
        this.loadGridListChange();
    }

    /**
     * Initialise les évenements de l'ajout au panier
     */
    loadAddToCart()
    {
        /**
         * @type {HTMLElement}
         */
        let btnList = document.getElementsByClassName('btn_add_to_cart');

    
        if (btnList.length > 0) {
            for (let i = 0; i < btnList.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let btn = btnList[i];
    
                if (btn.classList.contains('loaded')) {
                    continue;
                }
    
                btn.addEventListener('click', (e) => {
                    /**
                     * @type {Boolean}
                     */
                    let isGrid = true;

                    /**
                     * @type {Boolean}
                     */
                    let displaytype =  btn.getAttribute('data-displaytype');                   

                    /**
                     * @type {Boolean}
                     */
                    let isGridDisplayAdd = false;

                    /**
                     * @type {HTMLElement}
                     */
                    let quantityIncrement = document.getElementById(btn.getAttribute('data-quantity_increment'));

                    /**
                     * @type {HTMLElement}
                     */
                    let fakeQuantityInput = document.getElementById(btn.getAttribute('data-fake_quantity_input'));
    
                    /**
                     * @type {HTMLElement}
                     */
                    let quantityInput = document.getElementById(btn.getAttribute('data-quantity_input'));
    
                    /**
                     * @type {HTMLElement}
                     */
                    let operationInput = document.getElementById(btn.getAttribute('data-operation_input'));
    
                    /**
                     * @type {HTMLElement}
                     */
                    let addToCart = document.getElementById(btn.getAttribute('data-add_to_cart_for'));

                    /**
                     * @type {HTMLElement}
                     */
                    let goToProduct = document.getElementById(btn.getAttribute('data-go_to_product'));
    
                    /**
                     * @type {String}
                     */
                    let btnGridMode = btn.getAttribute('data-grid_mode');
    
                    if (btnGridMode == 'false') {
                        isGrid = false;
                    }

                    /**
                     * @type {String}
                     */
                    let btnDisplayAdd = btn.getAttribute('data-display_add');

                    if (btnDisplayAdd == 'true')  {
                        isGridDisplayAdd = true;
                    }

                    /**
                     * @type {HTMLElement}
                     */
                    let labelSpan = document.getElementById(btn.getAttribute('data-label'));
    
                    /**
                     * @type {String}
                     */
                    let labelAdd = btn.getAttribute('data-lang_add');

                    /**
                     * @type {HTMLElement}
                     */
                    let labelSpanQuick = document.getElementById(btn.getAttribute('data-quick'));

                    /**
                     * @type {String}
                     */
                    let labelQuick = btn.getAttribute('data-lang_quick');
    
                    /**
                     * @type {Integrer}
                     */
                    let quantityAsked = 1;
    
                    if (fakeQuantityInput != null) {
                        quantityAsked = parseInt(fakeQuantityInput.value);
                    }
    
                    /**
                     * @type {Integrer}
                     */
                    let currentCartQuantity = parseInt(btn.getAttribute('data-current_cart_product'));
    
                    /**
                     * @type {Integrer}
                     */
                    let quantityVariable = 0;
                    

                   
                    
                    if (isGrid && !isGridDisplayAdd) {
                        /**
                         * Ajouter un attribut display pour montrer l'incrémentation et le touchspin
                         * Ajouter les mêmes évenement qu'en liste
                         */
                        labelSpan.classList.remove('hide-pl-grid');
                        labelSpanQuick.classList.add('hide-pl-grid');
                        labelSpanQuick.classList.add('hide-pl-grid');
                        goToProduct.classList.add('hide-pl-grid');
                        btn.classList.add('display_icon');
                        btn.setAttribute('data-display_add', 'true');

                        if (fakeQuantityInput != null) {
                            fakeQuantityInput.parentElement.classList.add('displayed');
                            fakeQuantityInput.parentElement.style.display = 'block';
                        }

                        if (quantityIncrement != null) {
                            quantityIncrement.classList.add('displayed');
                            quantityIncrement.style.display = 'block';
                        }

                        if (btn.classList.contains('in_cart')) {
                            btn.style.backgroundColor = '#525252';
                            btn.disabled = true;
                        }

                        return;
                    }
                    
                    if (quantityAsked == currentCartQuantity) {
                        return;
                    }
    
                    btn.disabled = true;
                    btn.classList.add('loading');
    
                    /**
                     * Suppression du produit
                     */
                    if (quantityAsked == 0) {                    
                        /**
                         * @type {string}
                         */
                        let delete_action = btn.getAttribute('data-remove_from_cart');
    
                        btn.setAttribute('data-current_cart_product', 0);
    
                        return $.ajax({
                            url: delete_action,
                            method: 'GET',
                            data: {'ajax': 1},
                            dataType: 'json'
                        })
                        .fail((resp) => {
                            /**
                             * Par défaut, la requête de suppression n'est pas une requête ajax
                             */
                            if (resp.status == 200) {
                                btn.disabled = false;
                                btn.classList.remove('loading');
                                btn.classList.remove('in_cart');
                                labelSpan.innerHTML = labelAdd;
                                fakeQuantityInput.value = 1;
                                quantityInput.value = 1;
    
                                prestashop.emit('updateCart', {
                                    reason: 'update',
                                    resp
                                });
                            }
                        })
                        .then((resp) => {
                            if (resp.status == 200) {
                                btn.disabled = false;
                                btn.classList.remove('loading');
                                btn.classList.remove('in_cart');
                                labelSpan.innerHTML = labelAdd;
                                fakeQuantityInput.value = 1;
                                quantityInput.value = 1;
    
                                prestashop.emit('updateCart', {
                                    reason: 'update',
                                    resp
                                });
                            }
                        });
                    }
    
                    /**
                     * Modification de la quantité du produit
                     */
                    if (currentCartQuantity < quantityAsked) {
                        operationInput.value = 'up';
                        quantityVariable = quantityAsked - currentCartQuantity;
                    } else {
                        operationInput.value = 'down';
                        quantityVariable = currentCartQuantity - quantityAsked;
                    }
    
                    if (quantityVariable == 0) {
                        btn.disabled = false;
                        return;
                    }
    
                    quantityInput.value = quantityVariable;
    
                    addToCart.click();
                });
    
                btn.classList.add('loaded');
            }
        }
    
        /**
         * @type {HTMLElement}
         */
        let quantityInputList = document.getElementsByClassName('quantity_wanted_to_cart');
    
        if (quantityInputList.length > 0) {
            for (let q = 0; q < quantityInputList.length; q++) {
                /**
                 * @type {HTMLElement}
                 */
                let input = quantityInputList[q];
    
                if (input.classList.contains('loaded_event')) {
                    continue;
                }
    
                $(input).on('change keyup blur', (e) => {
                    /**
                     * @type {HTMLElement}
                     */
                    let btnAddToCart = document.getElementById(input.getAttribute('data-button_add'));
    
                    /**
                     * @type {Integrer}
                     */
                    let quantityAsked = parseInt(input.value);
    
                    /**
                     * @type {Integrer}
                     */
                    let currentQuantity = parseInt(btnAddToCart.getAttribute('data-current_cart_product'));
    
                    if (quantityAsked == currentQuantity) {
                        btnAddToCart.disabled = true;
                    } else {
                        btnAddToCart.disabled = false;
                    }
                });   
                
                $(input).on('keypress', (e) => {
                    if(e.which == 13) {
                        let btnAddToCart = document.getElementById(input.getAttribute('data-button_add'));
                        e.preventDefault();
                        //$(btnAddToCart).trigger('click');
                    }
                });
    
                input.classList.add('loaded_event')
            }
        }
    }

    /**
     * Initialise les touchspins
     */    
    loadTouchSpin()
    {
        /**
         * Touchspin
         * 
         * @type {HTMLElement}
         */
        let inputs = document.getElementsByClassName('input_touchspin');
    
        if (inputs.length > 0) {
            for (let i = 0; i < inputs.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let input = inputs[i];
    
                if (input.classList.contains('loaded')) {
                    continue;
                }
                
                /**
                 * @type {HTMLElement}
                 */
                let quantityInput = $(input);
    
                quantityInput.TouchSpin({
                    verticalbuttons: true,
                    verticalupclass: 'material-icons touchspin-up',
                    verticaldownclass: 'material-icons touchspin-down',
                    buttondown_class: 'btn btn-touchspin js-touchspin',
                    buttonup_class: 'btn btn-touchspin js-touchspin',
                    min: parseInt(quantityInput.attr('min'), 10),
                    max: 1000000,
                    step: parseInt(quantityInput.attr('step'), 10)
                });
            
                $(prestashop.themeSelectors.touchspin).off('touchstart.touchspin');
            
                quantityInput.on('focusout', () => {
                if (quantityInput.val() === '' || quantityInput.val() < quantityInput.attr('min')) {
                    quantityInput.val(quantityInput.attr('min'));
                    quantityInput.trigger('change');
                }
                });
            
                $('body').on('change keyup', prestashop.selectors.quantityWanted, (e) => {
                if (quantityInput.val() !== '') {
                    $(e.currentTarget).trigger('touchspin.stopspin');
                    prestashop.emit('updateProduct', {
                    eventType: 'updatedProductQuantity',
                    event: e,
                    });
                }
                });
    
                input.classList.add('loaded');
            }
        }
    }

    /**
     * Initialise les boutons d'incrémentations
     */
    loadIncrement()
    {
        /**
         * @type {HTMLElement}
         */
        let btnList = document.getElementsByClassName('btn_increment');
    
        if (btnList.length > 0) {
            for (let i = 0; i < btnList.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let btn = btnList[i];
    
                if (btn.classList.contains('loaded')) {
                    continue;
                }
    
                btn.addEventListener('click', (e) => {
                    /**
                     * @type {string}
                     */
                    let targetId = btn.getAttribute('data-increment_for');
    
                    /**
                     * @type {integrer}
                     */
                    let increment = parseInt(btn.getAttribute('data-increment_value'));
    
                    if (targetId && increment) {
                        /**
                         * @type {HTMLElement}
                         */
                        let target = document.getElementById(targetId);
    
                        /**
                         * @type {integrer}
                         */
                        let value = parseInt(target.value);
    
                        if (target) {
                            if (value == 1) {
                                target.value = value + (increment - 1);
    
                            } else {
                                target.value = value + increment;
                            }
                        }
    
                        /**
                         * @type {HTMLElement}
                         */
                        let btnAddToCart = document.getElementById(btn.getAttribute('data-button_add'));
    
                        /**
                         * @type {Integrer}
                         */
                        let quantityAsked = parseInt(target.value);
    
                        /**
                         * @type {Integrer}
                         */
                        let currentQuantity = parseInt(btnAddToCart.getAttribute('data-current_cart_product'));
    
                        if (quantityAsked == currentQuantity) {
                            btnAddToCart.disabled = true;
                        } else {
                            btnAddToCart.disabled = false;
                        }
                    }
                });
    
                btn.classList.add('loaded');
            }
        }
    }

    /**
     * Initialise le changement de déclinaisons en mode grille
     */
    loadProductVariants()
    {
        /**
         * @type {HTMLElement}
         */
        let productVariants = document.getElementsByClassName('product-variants');
    
        if (productVariants.length > 0) {
            for (let i = 0; i < productVariants.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let variant = productVariants[i];

                /**
                 * @type {HTMLElement}
                 */
                let selects = variant.getElementsByTagName('select');
        
                if (selects) {
                    /**
                     * @type {HTMLElement}
                     */
                    let select = selects[0];
        
                    select.addEventListener('change', (e) => {
                        /**
                         * @type {number}
                         */
                        let id_product = select.getAttribute('data-id_product');
    
                        /**
                         * @type {number}
                         */
                        let id_product_attribute = select.getAttribute('data-id_product_attribute');
                        
                        /**
                         * @type {number}
                         */
                        let id_attribute = select.value;
    
                        /**
                         * @type {HTMLElement}
                         */
                        let product_miniature_link = document.getElementById('product_miniature_link_' + id_product);
    
                        /**
                         * @type {HTMLElement}
                         */
                        let product_image_loader = document.getElementById('product_image_loader_' + id_product);
    
                        if (product_miniature_link) {
                            if (product_image_loader) {
                                product_image_loader.style.display = 'flex';
                            }

                            /**
                             * @type {HTMLElement}
                             */ 
                            let btnContainer = document.getElementById('product_container_add_to_cart_' + id_product + '_' + id_product_attribute + '_1');
                            
                            this.#updateXyProductListProductAddToCart(id_product, id_attribute).then((response) => {
                                // Actions d'ajout du bouton en JS
                                if (!response.success) {
                                    alert('Error during process');
                                    return;
                                }

                                if (response.html) {
                                    btnContainer.insertAdjacentHTML('beforebegin', response.html);
                                    btnContainer.remove();
                                }

                                if (response.id_product_attribute) {
                                    select.setAttribute('data-id_product_attribute', response.id_product_attribute);
                                }
                                
                                this.#updateXyProductListProductImages(id_product,id_attribute).then((result) => {
                                    /**
                                     * @type {HTMLElement}
                                     */
                                    let product_miniature_link = document.getElementById('product_miniature_link_' + id_product);
    
                                    product_miniature_link.innerHTML = result.images;
    
                                    if (result.images && result.images.length > 0){
                                        product_miniature_link.classList.remove('loading');
                                        product_miniature_link.innerHTML = result.images;
                                        product_image_loader.style.display = 'none';
                                    } else {
                                        product_miniature_link.classList.remove('loading');
                                        product_image_loader.style.display = 'none';
                                    }

                                    this.loadAll();
                                });
                            });
                        }
    
                        /**
                         * @type {Array}
                         */
                        let options = select.getElementsByTagName('option');
    
                        if (options.length > 0) {
                            for (let i = 0; i < options.length; i++) {
                                /**
                                 * @type {HTMLElement}
                                 */
                                let opt = options[i];
    
                                if (opt.value != id_attribute) {
                                    continue;
                                }
    
                                /**
                                 * @type {Integrer}
                                 */
                                let quantity = parseInt(opt.getAttribute('data-quantity'));
    
                                /**
                                 * @type {Integrer}
                                 */
                                let allow_oosp = parseInt(opt.getAttribute('data-allow_oosp'));
    
                                /**
                                 * @type {HTMLElement}
                                 */
                                let btnAddToCart = document.getElementById('custom-add-to-cart-' + id_product + '-' + id_product_attribute + '-1');
    
                                if (btnAddToCart && quantity <= 0 && allow_oosp == 0) {
                                    btnAddToCart.disabled = true;
                                    btnAddToCart.setAttribute('data-disabled_on_grid', 'true');
                                } else {
                                    btnAddToCart.disabled = false;
                                    btnAddToCart.setAttribute('data-disabled_on_grid', 'false');
                                }
                            }
                        }
                    });
                }

                variant.classList.add('loaded');
            }
        }
    }

    /**
     * Initialise le bouton affichant les combinaisons
     */
    loadSelectCombination()
    {
        /**
         * @type {HTMLElement}
         */
        let btnSelectCombination = document.getElementsByClassName('btn-select-combination');
    
        if (btnSelectCombination.length > 0) {
            for (let i = 0; i < btnSelectCombination.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let btn = btnSelectCombination[i];

                if (btn.classList.contains('loaded')) {
                    continue;
                }
                
                btn.addEventListener('click', (e) => {
                    /**
                     * @type {Number}
                     */
                    let id_product = btn.getAttribute('data-id_product');
    
                    if (!btn.classList.contains('loaded_table')) {
                        
                        btn.disabled = true;
                        btn.classList.add('loading');
    
                        this.#getProductCombination(id_product).then((response) => {
                            btn.disabled = false;
                            btn.classList.add('loaded_table');
                            btn.classList.remove('loading');
                            
                            if (response.combinations && response.combinations.length > 0) {
                                /**
                                 * @type {HTMLElement}
                                 */
                                let container = document.getElementById('combination_table_' + id_product);
    
                                container.innerHTML = response.combinations;

                                this.loadAll();
    
                                btn.click();
                            }
                        });
                    }
                });

                btn.classList.add('loaded');
            }
        }
    }

    /**
     * Evenement assurant l'affichage grille/liste des éléments
     */
    loadGridListChange()
    {
        /**
         * @type {array}
         */
        let gridList = document.getElementsByClassName('xyproductlist-grid-list-button');
    
        /**
         * @type {boolean}
         */
        let displayVariant = false;
    
        /**
         * @type {boolean}
         */
        let displaySelectCombination = false;
    
        /**
         * @type {boolean}
         */
        let gridListExist = false;

        if (gridList.length > 0) {
            gridListExist = true;
            for (let i = 0; i < gridList.length; i++) {
                let list = gridList[i];
                
                if (list.classList.contains('active') && list.classList.contains('grid')) {
                    displayVariant = true;
                    this.setGridModeToProduct('grid');
                    break;
                }
    
                if (list.classList.contains('active') && list.classList.contains('list')) {
                    displaySelectCombination = true;
                    this.setGridModeToProduct('list');
                    break;
                }
            }
        }

        if (!gridListExist) {
            if (prestashop.page.page_name == 'product') {
                displayVariant = false;
            } else {
                displayVariant = true;
            }
        }
        
        /**
         * @type {HTMLElement}
         */
        let productVariants = document.getElementsByClassName('product-variants');
    
        if (productVariants.length > 0) {
            for (let i = 0; i < productVariants.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let variant = productVariants[i];
    
                variant.style.display = displayVariant == true ? 'block' : 'none';
    
                if (displayVariant) {
                    variant.style.display = 'block';
                } else {
                    variant.style.display = 'none';
                }
            }
        }

        /**
         * @type {HTMLElement}
         */
        let selectCombinations = document.getElementsByClassName('select_combinations');

        if (selectCombinations.length > 0) {
            for (let i = 0; i < selectCombinations.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let select = selectCombinations[i];
    
                select.style.display = displayVariant == true ? 'none' : 'block';
            }
        }

        /**
         * @type {HTMLElement}
         */
        let btnAddToCart = document.getElementsByClassName('btn-add-to-cart');

        
    
        if (btnAddToCart.length > 0) {
            for (let i = 0; i < btnAddToCart.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let btn = btnAddToCart[i];
    
                if (btn.classList.contains('has_combination')) {
                    btn.style.display = displaySelectCombination == true ? 'none' : 'block';
                } else {
                    btn.style.display = 'block';
                }
                
    
                /**
                 * @type {String}
                 */
                let grid_mode = btn.getAttribute('data-grid_mode');


                let displaytype = '';

                displaytype =  btn.getAttribute('data-displaytype');


                if(displaytype && displaytype=='grid'){
                    grid_mode = true;
                }
    
                if (grid_mode == 'false') {
                    /**
                     * @type {HTMLElement}
                     */
                    let quantityAskedInput = document.getElementById(btn.getAttribute('data-fake_quantity_input'));
    
                    if (quantityAskedInput) {
                        /**
                         * @type {Integrer}
                         */
                        let currentQuantity = parseInt(btn.getAttribute('data-current_cart_product'));
    
                        /**
                         * @type {Integrer}
                         */
                        let quantityAsked = parseInt(quantityAskedInput.value);
    
                        if (currentQuantity == quantityAsked) {
                            btn.disabled = true;
                        }
                    }
                } else {
                    /**
                     * @type {String}
                     */
                    let disabled_on_grid = btn.getAttribute('data-disabled_on_grid');
    
                    if (disabled_on_grid == 'true') {
                        btn.disabled = true;
                    }else {
                        btn.disabled = false;
                    }
                }
            }
        }
    
        /**
         * @type {HTMLElement}
         */
        let btnIncrement = document.getElementsByClassName('btn_increment');
    
        if (btnIncrement.length > 0) {
            for (let i = 0; i < btnIncrement.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let btn = btnIncrement[i];

                if (btn.classList.contains('has_combination')) {
                    if (!displayVariant) {
                        btn.style.display = 'none';
                    }
                } else {
                    btn.style.display = displayVariant == true ? 'none' : 'block';
                }
                
                if (displayVariant && btn.classList.contains('displayed')) {
                    btn.style.display = 'block';
                }
            }
        }
    
        /**
         * @type {HTMLElement}
         */
        let quantityWantedToCart = document.getElementsByClassName('quantity_wanted_to_cart');
    
        if (quantityWantedToCart.length > 0) {
            for (let i = 0; i < quantityWantedToCart.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let btn = quantityWantedToCart[i];
                
                if (
                    btn.classList.contains('has_combination') ||
                    btn.parentElement.classList.contains('has_combination')
                ) {
                    btn.parentElement.classList.add('has_combination');

                    if (!displayVariant) {
                        btn.parentElement.style.display = 'none';
                    }
                } else {
                    btn.parentElement.style.display = displayVariant == true ? 'none' : 'block';
                }
                
                if (displayVariant && btn.parentElement.classList.contains('displayed')) {
                    btn.parentElement.style.display = 'block';
                }                 
                      
            }
        }
    }

    /**
     * On déclare aux boutons d'ajout leur méthode d'ajout
     * 
     * @param {string} display 
     */
    setGridModeToProduct(display)
    {
        /**
         * @type {Boolean}
         */
        let isGrid = display == 'list' ? false : true;

        /**
         * @type {HTMLElement}
         */
        let btnAddToCartList = document.getElementsByClassName('btn_add_to_cart');

        if (btnAddToCartList.length > 0) {
            for (let i = 0; i < btnAddToCartList.length; i++) {
                /**
                 * @type {HTMLElement}
                 */
                let btn = btnAddToCartList[i];

                if (isGrid) {
                    btn.setAttribute('data-grid_mode', 'true');
                } else {
                    btn.setAttribute('data-grid_mode', 'false');
                }
            }
        }
    }
}