import Splide from '@splidejs/splide/dist/js/splide';

var homeProductLists = [
    '#featured-products',
    '#new-products',
    '#new-equipment'
];

function initProductsCarousels(isMobile)
{
    if (typeof Splide == 'function' && homeProductLists.length > 0) {
        homeProductLists.forEach(splideSelector => {
            if ($(splideSelector).length > 0) {
                new Splide(splideSelector, {
                    type: 'slide',
                    rewind: true,
                    autoplay: false,
                    pauseOnHover: true,
                    pagination: false,
                    speed: 1000,
                    gap: '7px',
                    perPage: 5,
                    perMove: 1,
                    breakpoints: {
                        991: {
                            perPage: 2,
                            arrows: false
                        }
                    }
                }).mount();
            }
        });
    }
    if (typeof Splide == 'function' && $('#accessories-products').length > 0) {
        if (isMobile) {
            if ($('#accessories-products .splide__list .product').length > $('#accessories-products').data('splide_max_mobile')) {
                $('#accessories-products').removeClass('hide_splide_arrows');
            } else {
                $('#accessories-products').addClass('hide_splide_arrows');
            }
        } else {
            if ($('#accessories-products .splide__list .product').length > $('#accessories-products').data('splide_max_desktop')) {
                $('#accessories-products').removeClass('hide_splide_arrows');
            } else {
                $('#accessories-products').addClass('hide_splide_arrows');
            }
        }
        new Splide('#accessories-products', {
            type: 'slide',
            rewind: true,
            autoplay: false,
            pauseOnHover: true,
            pagination: false,
            speed: 1000,
            gap: '0px',
            perPage: 5,
            perMove: 1,
            arrowPath: 'M12.236,2.703l15.664,15.729c0.247,0.233 0.386,0.557 0.386,0.896c-0,0.338 -0.139,0.662 -0.386,0.895l-15.664,15.729" style="fill:none;fill-rule:nonzero;stroke:rgb(34,34,34);stroke-width:3.44px;',
            breakpoints: {
                1639: {
                    perPage: 4
                },
                991: {
                    perPage: 2,
                    arrows: false
                }
            }
        }).mount();
    }
    if (typeof Splide == 'function' && $('#xyaccessories-products').length > 0) {
        if (isMobile) {
            if ($('#xyaccessories-products .splide__list .product').length > $('#xyaccessories-products').data('splide_max_mobile')) {
                $('#xyaccessories-products').removeClass('hide_splide_arrows');
            } else {
                $('#xyaccessories-products').addClass('hide_splide_arrows');
            }
        } else {
            if ($('#xyaccessories-products .splide__list .product').length > $('#xyaccessories-products').data('splide_max_desktop')) {
                $('#xyaccessories-products').removeClass('hide_splide_arrows');
            } else {
                $('#xyaccessories-products').addClass('hide_splide_arrows');
            }
        }
        new Splide('#xyaccessories-products', {
            type: 'slide',
            rewind: true,
            autoplay: false,
            pauseOnHover: true,
            pagination: false,
            speed: 1000,
            gap: '0px',
            perPage: 5,
            perMove: 1,
            arrowPath: 'M12.236,2.703l15.664,15.729c0.247,0.233 0.386,0.557 0.386,0.896c-0,0.338 -0.139,0.662 -0.386,0.895l-15.664,15.729" style="fill:none;fill-rule:nonzero;stroke:rgb(34,34,34);stroke-width:3.44px;',
            breakpoints: {
                1639: {
                    perPage: 4
                },
                991: {
                    perPage: 2,
                    arrows: false
                }
            }
        }).mount();
    }
}

$(function () {
    initProductsCarousels(prestashop.responsive.mobile);
    prestashop.on('responsive update', (event) => {
        initProductsCarousels(prestashop.responsive.mobile);
    });
});
